@charset "UTF-8";
@font-face {
  font-family: Zelek;
  src: url(../fonts/ZelekFont.ttf);
}
@font-face {
  font-family: Audiowide;
  src: url(../fonts/Audiowide-Regular.ttf);
}

* {
  box-sizing: border-box;
}
body {
  width: auto;
  margin: 0;
  padding: 0;
  max-width: 100%;

  font-family: 'Avenir Next Cyr', serif;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}
button {
  outline: none;
  cursor: pointer;
}
ul {
  list-style: none;
}
      
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
form [submit-error],
form [submit-success] {
  margin-bottom: 20px;
}
form [submit-error] div,
form [submit-success] div {
  font-size: 18px;
  line-height: 1.4;
  color: #FF3C21;
  text-align: center;
  font-family: Avenir Next Cyr;
}
form [submit-error] strong,
form [submit-success] strong {
  text-decoration: underline;
}
form [submit-success] div {
  margin-top: 20px;
  color: #0B0A0A;
}

/* common styles */

.container {
  max-width: 100%;
  padding: 30px 15px;
  overflow-x: hidden;

  position: relative;
}
.row {
  width: 100%;
  /* margin-right: -15px; */
  /* margin-left: -15px; */

  position: relative;
}

.orange-text {
  color: #FEAE37;
}
.bold-text {
  font-weight: 800;
}
.pb60 {
  padding-bottom: 60px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb20 {
  padding-bottom: 20px;
}
.common-button_orange { 
  width: 15rem;
  max-width: 100%;
  height: 3rem;
  padding: 0;

  position: relative;

  background:
    linear-gradient(180deg, rgba(255, 255, 255, 0) 96.53%, rgba(255, 255, 255, 0.21) 100%),
    linear-gradient(180deg, #FEAE37 0%, #FE7F37 100%), #C4C4C4;
  border-radius: 40px;
  border: none;

  font-size: 1rem;
  line-height: 1.52;
  color: #0B0A0A;
  font-weight: 800;
  letter-spacing: 0.1em;
  font-family: sans-serif;
}
.common-button_orange::after{
  content: '';

  height: 3rem;

  position: absolute;
  left: 3px;
  right: 3px;
  top: 4px;

  background: linear-gradient(180deg, #FEAE37 0%, #C96126 100%), #C4C4C4;
  border-radius: 40px;

  z-index: -1;
}
.common-button_orange:hover{
  background: linear-gradient(180deg, #FEAE37 0%, #C96126 100%), #C4C4C4;
}

.carousel-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  position: absolute;
  z-index: 1;
  bottom: 25px;
}
.carousel-pagination span {
  display: inline-block;

  margin: 0 5px;
  height: 12px; 
  width: 12px;

  background-color: #FEAE37;
  border: 4px solid #2C2C2C;
  border-radius: 50%;
}
.carousel-pagination span.active {
  border: none;
}
.container-header .carousel-pagination {
  bottom: 30px;
}
.title {
  margin: 20px 0 40px;

  font-size: 36px;
  line-height: 1.4;
  color: #0B0A0A;
  text-align: center;
  font-weight: bold;
  font-family: Zelek;
}
.title::after {
  content: '';

  display: block;
  height: 8px;
  width: 60px;
  margin: 20px auto;

  background: linear-gradient(180deg, #FEAD37 0%, #FE8337 100%);
  border-radius: 4px;
}
.text {
  font-family: 'Avenir Next Cyr',serif;
  font-size: 16px;
  line-height: 1.52;
  color: #0B0A0A;
}
  
/* finished common styles */

form.amp-form-submit-success fieldset {
  display: none;
}

form.amp-form-submit-success .common-button_orange {
  display: none;
}

/* NAVBAR */

.container-navbar {
  display: flex;
  align-items: center;

  width: 100%;
  height: 60px;
  margin: 0;
  padding: 0;

  position: fixed;
  top: 0;

  background-color: #0B0A0A;
  box-shadow: 0px 1px 5px 1px rgba(0,0,0,0.4);

  z-index: 3;
}

.navbar-block-mobile, .navbar-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
}
.navbar-block {
  display: none;
  max-width: 1290px;
  margin: auto;

  font-weight: normal;
}
.navbar-block ul {
  display: flex;
  align-items: center;

  padding: 0;
  margin: 0;
}
.navbar-block ul li a {
  display: flex;

  color: #fff;
  padding: 20px 10px;
}
.navbar-block ul li:hover a {
  color: #FEAE37;
}
.navbar-block ul li a.logo {
  padding: 0;
  margin-right: 20px;
}
.navbar a.phone {
  display: flex;
  align-items: center;

  color: #fff;
  font-family: sans-serif;
}
.navbar .phone {
  font-weight: bold;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px;
  height: 30px;
  width: 30px;

  border: none;
  background-color: #000000;
}

.phone-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 34px;
  height: 34px;
  margin-right: 10px;

  border-radius: 50%;
  background: linear-gradient(180deg, #FEAD37 0%, #FE8337 100%);
}
.phone-icon-wrap .phone-icon {
  width: 14px;
  height: 14px; 
  background: url('../img/phone-black-icon.png') no-repeat center center;
}
.phone:hover .phone-icon-wrap {
  background: linear-gradient(180deg, #FEAE37 0%, #C96126 100%), #C4C4C4;
}
.phone:hover .phone-icon {
  background: url('../img/phone-white-icon.png') no-repeat center center;
}

/* FINISH NAVBAR */

/* HEADER */
.header {
  min-height: 100%;
  width: 100%;
  padding: 80px 20px 30px;

  position: absolute;
  top: 0;
  left: 0;

  background: linear-gradient(179.62deg, rgba(30, 20, 32, 0) 3.14%, #1E1420 75%);

  color: #fff;
  text-align: center;
  line-height: 1.1;

  z-index: 1;
}
.container-header {
  padding: 0 0 0;
}
.container-header .row {
  margin-left: 0;
  margin-right: 0;
}
.header::before {
  content: '';

  width: 110px;
  height: 90px;

  position: absolute;
  top: 15%;
  left: -25px;
  transform: scaleX(-1);

  background: url('../img/bee-img.png') no-repeat center center;

  z-index: 0;
}
.header::after {
  content: '';

  width: 120px;
  height: 115px;

  position: absolute;
  top: 45%;
  right: -32px;

  background: url('../img/flower-img.png') no-repeat center center;

  z-index: 0;
}
.header h4 {
  margin: 0 0 20px 0;

  font-family: Zelek;
  font-weight: bold;
  font-size: 44px;
  line-height: 1.1;
}
.header p {
  font-size: 18px;
  line-height: 1.52;
  letter-spacing: 0.2px;
}


/* FINISH HEADER */
  
.container-about {
  padding-top: 30px;
}

.container-about .text {
  margin: 20px 0;
}
.container-team,
.container-product,
.container-review {
  margin-bottom: 0;
  padding-top: 40px;

  background: url('../img/team-pattern.svg') no-repeat ;
  background-size: cover;
}
.container-review {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 120px;
  margin-bottom: 30px;
  
  background: url('../img/review-pattern.svg') no-repeat bottom;
  background-size: cover;
}

/* TEAM */

.container-team {
  padding-left: 0;
  padding-right: 0;
}
.container-team_person {
  align-items: center;
  flex-direction: column;
  text-align: center;

  min-height: 250px;
}
.container-team_person-text {
  display: none;

  font-family: 'Avenir Next Cyr',serif;
  font-size: 16px;
  line-height: 1.52;
  color: #0B0A0A;
  text-align: center;
}
.container-team_person-name {
  display: none;

  margin: 20px 0 10px;

  font-weight: 800;
  font-size: 18px;
  line-height: 1.56;
  color: #0B0A0A;
}
.container-team_person-img {
  width: 120px;
  height: 120px;
  margin: auto;

  position: relative;
}
.container-team_person-img amp-img{
  margin: 20px;
}
.container-team_person.active .container-team_person-img {
  width: 140px;
  height: 140px;
}
.container-team_person.active .container-team_person-name,
.container-team_person.active .container-team_person-text {
  display: block;
}
.container-team_person.active .container-team_person-img amp-img{
  margin: 0;
}
.carousel-team {
  z-index: 2;
}
.carousel-team .amp-carousel-button-prev {
  display: none;

  margin: auto auto 33%;

  background-image: url('../img/left-arrow.svg');
  background-size: 36px 36px;
  background-color: unset;
  outline: none;
  border-radius: 48%;
  cursor: pointer;
  
}
.carousel-team .amp-carousel-button-next {
  display: none;

  margin: auto auto 33%;

  background-image: url('../img/right-arrow.svg');
  background-size: 36px 36px;
  background-color: unset;
  outline: none;
  border-radius: 48%;
  cursor: pointer;
}
/* FINISH TEAM */

.container-team .carousel-pagination span,
.container-review .carousel-pagination span{
  border-color: #F8DBCB;
}



.container-stats,
.container-price,
.container-contacts {
  margin-bottom: 0;

  background-color: #1E1420;
}
.container-price {
  padding-left: 0;
  padding-right: 0;
}

.container-stats::before {
  content: '';

  width: 160px;
  height: 116px;

  position: absolute;
  bottom: 20%;
  left: -80px;

  background: url('../img/honey-comb.png') no-repeat center center;

  z-index: 0;
}
.container-stats::after {
  content: '';

  width: 110px;
  height: 90px;

  position: absolute;
  top: 50%;
  right: 0px;
  
  background: url('../img/bee-img.png') no-repeat center center;

  z-index: 0;
}

.container-stats .title,
.container-price .title,
.container-contacts .title {
  color: #fff;
}
.stats-row {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 30px;

  color: #fff;
  font-size: 24px;
  font-family: 'Avenir Next Cyr',serif;
}
.stats-row_text {
  background: linear-gradient(180deg, #FEAD37 0%, #FE8337 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 70px;
  color: #0B2349;
  text-align: center;
  font-weight: bold;
  font-family: Zelek;
  line-height: 1.2;
}
.process-block {
  display: flex;

  max-width: 320px;
  margin: 0px auto 30px;

  position: relative;
}
.process-block_number {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;

  font-family: Zelek;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;

  border-radius: 50%;
  color: #000000;

  background: linear-gradient(180deg, #FEAD37 0%, #FE8337 100%);
  border: 5px solid #ffe8d7;
}
.process-block .process-block_number::before {
  content: '';

  width: 29px;
  height: 230px;

  position: absolute;
  bottom: 0px;
  
  background: url('../img/honey-drop.png') no-repeat center center;

  z-index: 0;
}
.process-block:last-child .process-block_number::before {
  content: unset;
}

.process-block_info {
  display: flex;
  flex-direction: column;
  
  margin-left: 20px;

  font-family:' Avenir Next Cyr',serif;
  line-height: 1.62;
}
.process-block_info h4 {
  margin: 10px 0;

  font-weight: 750;
  font-size: 18px;
  color: #0B0A0A;
}
.process-block_info p {
  margin-bottom: 20px;

  line-height: 1.62;
}
.process-block_info img {
  margin-top: auto;

  max-width: 100%;
}
.product-row {
  display: flex;
  align-items: center;

  max-width: 400px;

  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  color: #000;
}
.product-row div {
  margin-right: 15px;
}

.price-card {
  margin: 0px 10px;

  background: #FFFFFF;
  border-radius: 20px;

  text-align: center;

  overflow: hidden;
}

.price-card_img {
  width: 100%;
  height: 230px;

  position: relative;
}

.price-card_price {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 20px 10px;
}
.price-card_price h4 {
  margin: 0;

  font-family: Zelek;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.2;
  color: #FEAE37;
}
.price-card_price h4 span {
  font-size: 18px;
}
.price-card_price div {
  display: flex;
  align-items: center;
}
.price-card_price div p {
  margin-left: 5px;
}
.price-card_text {
  margin: 0 20px;

  text-align: left;
  font-size: 14px;
  line-height: 1.57;
  /* color: #AAAAAA; */
  color:#000;
  font-family: 'Avenir Next Cyr',serif;
}
.price-card_button {
  position: relative;
  z-index: 1;
}
.price-card .common-button_orange {
  margin: 15px 0 30px;
  width: 146px;
  height: 46px;

  font-size: 12px;
}
.price-card .common-button_orange::after{
  height: 46px;

  left: 2px;
  right: 2px;
  top: 4px;
}

.container-delivery {
  text-align: center;
}
/* .container-delivery p {
  text-align: left;
  margin: auto auto 30px;
  max-width: 500px;
} */

.review-card {
  min-height: 320px;
  max-width: 400px;
  padding: 30px;
  margin: 0 auto;

  background: #FFFFFF;
  border-radius: 20px;

  text-align: center;
  overflow: hidden;
}
.review-card_person {
  display: flex;
  align-items: center;
  
  margin-bottom: 20px;
}
.review-card .text {
  text-align: left;
}
.review-card_person-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-left: 20px;
  
  line-height: 1.56;
}
.review-card_person-info span {
  color: #BBBBBB;
}
.review-card_person-info h4 {
  margin: 0px;

  font-weight: bold;
  font-size: 18px;
}
.review-card_person-info div {
  display: flex;
  align-items: center;

  margin: 5px 0;
}
.review-card_person-info div img {
  margin-right: 5px;
}
.container-contacts {
  padding-bottom: 0;
}

.container-contacts::before {
  content: '';

  width: 130px;
  height: 241px;

  position: absolute;
  top: 10%;
  right: -20px;

  background: url('../img/contact-honey.png') no-repeat center center;

  z-index: 0;
}
/* .container-contacts .title{
  text-align: left;
} */
/* .container-contacts .title::after {
  margin: 20px 0;
} */
.contacts-row {
  display: flex;
  align-items: center;

  margin-bottom: 30px;
}
.contacts-row p, .contacts-row a {
  margin-left: 10px;

  font-size: 19px;
  line-height: 1.2;
  color: #fff;
}
.copyright {
  width: 100%;
  margin-top: 60px;
  padding: 20px 0 25px;

  color: rgba(255,255,255,0.5);
  border-top: 1px solid rgba(255,255,255,0.1);

  font-family: 'Avenir Next Cyr',serif;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
}
.rounded-img {
  border-radius: 20px;
}
.rounder-img_50 {
  border-radius: 50%;
}
.cover-img img {
  object-fit: cover;
}
amp-lightbox {
  background: #fff;
  overflow-y: auto;
}
.modal {
  /* max-width: 100%; */
  /* padding: 120px 15px 40px; */

  /* background: url('../img/modal-bg.png') no-repeat center 0;
  background-size: auto; */
  
  /* text-align: center; */
  opacity: 1!important;
}
.modal-close {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  margin: auto;
  padding: 0;

  position: absolute;
  top: -70px;
  right: 0;
  
  border: none;
  border-radius: 50%;
  background: linear-gradient(180deg, #FEAD37 0%, #FE8337 100%);
}
.modal-title {
  margin: 10px 0;

  font-size: 36px;
  line-height: 1.4;
  color: #FEAE37;
  text-align: center;
  font-weight: bold;
  font-family: Zelek;
}
.modal p {
  margin-bottom: 20px;

  font-family: Avenir Next Cyr;
  font-size: 18px;
  line-height: 1.56;
  text-align: center;
  color: #0B0A0A;
}
.modal input,.modal select {
  width: 100%;
  padding: 15px 20px 15px 35px;
  margin-bottom: 20px;

  background: #F4F4F4;
  border-radius: 50px;
  border: none;

  font-family: Avenir Next Cyr;
  font-size: 16px;
  line-height: 1.75;
  color: #0B0A0A;

  outline: none;
}
.modal select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 50%;
}

.modal fieldset {
  border: none;
}
.modal form {
  max-width: 320px;
  margin: auto;
  position: relative;
  z-index: 1;
}
amp-sidebar {
  background-color: #fff;
}
amp-sidebar ul {
  padding: 0 20px;
}
amp-sidebar ul li {
  margin-bottom: 10px;
}
amp-sidebar ul li a {
  display: block;

  padding: 5px 15px;

  color: #0B0A0A;
  text-decoration: none;
  font-size: 17px;
}

.carousel-header .amp-carousel-button-prev,
.carousel-header .amp-carousel-button-next,
amp-base-carousel .carousel-price-arrow-prev,
amp-base-carousel .carousel-price-arrow-next,
.visible-web-block {
  display: none;
}

.container-review .carousel-prev,
.container-review .carousel-next {
  display: none;
}

@media (max-width: 420px) {
  .carousel-pagination,
  .container-header .carousel-pagination,
  .container-review .carousel-pagination {
    bottom: -30px;
    margin: 20px 0;

    position: relative;
  }
  .container-header .carousel-pagination{
    bottom: -60px;
    margin: 30px 0;
    position: relative;
  }
  .container-price .carousel-pagination {
    margin: 0 0 10px 0;
  }
  .container-review .carousel-pagination{
    bottom: unset;
    margin: 10px 0;
    position: relative;
  }
}
@media (min-width: 374px) {
  .container-team .pb40 {
    padding-bottom: 0;
  }
}
@media (max-width: 500px) {
  /* .review-card {
    margin: 0 20px;
  } */
  .price-card {
    margin: 0 20px;
  }
  .price-card_ml {
    margin: 0 0 0 40px;
  }
  .price-card_mr {
    margin: 0 40px 0 0;
  }
}

@media (min-width: 800px) {
  .container-price .carousel-pagination {
    display: none;
  }
  .container-review .carousel-next,
  .container-review .carousel-prev {
    display: flex;
    background: rgba(254, 174, 55,0.3);
  }
  .container-review .carousel-next:hover,
  .container-review .carousel-prev:hover {
    background: rgba(254, 174, 55,0.8);
  }
}


@media (min-width: 968px) {
  .visible-web-block {
    display: block;
  }
  .container {
    margin-bottom: 80px;
  }
  .container-about {
    max-width: 1070px;
    width: 100%;
    margin: auto auto 80px;
  }
  .container-about {
    display: flex;
    flex-direction: row-reverse;
  }
  .container-about .title {
    text-align: left;
  }
  .container-about .title::after {
    /*   .container-delivery .title::after */
    margin: 20px 0;
  }
  
  .container-about >div {
    max-width: 520px;
  }
  .container-about .row {
    max-width: 500px;
    margin-right: 90px;
    margin-left: 0;
  }
  .container-about .row p {
    display: none;
  }
  .container-navbar {
    position: absolute;
    top: 30px;

    background-color: transparent;
    box-shadow: none;
    overflow: hidden;
  }
  .container-header .pb60 {
    padding-bottom: unset;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0;

    background: linear-gradient(rgba(30, 20, 32, .7) ,rgba(30, 20, 32, 0) 100%);
  }
  .header:before,.header:after {
    content: unset;
  }
  .header h4 {
    font-size: 58px;
    line-height: 1.29;
  }
  .header p {
    font-size: 24px;
    line-height: 1.42;
  }
  .header p.orange-text {
    color: #FF3C21;
  }
  .header h4 span br {
    display: none;
  }
  
  .navbar-block-mobile {
    display: none;
  }
  .navbar-block {
    display: flex;
  }
  .container-header {
    padding: 0;
  }
  .container-team, .container-product {
    padding-top: 180px;
    margin-bottom: 0;
  }
  .container-team::before {
    content: '';

    width: 185px;
    height: 660px;

    position: absolute;
    top: 150px;
    left: 0;

    background: url('../img/bg2.png') no-repeat center center;

    z-index: 0;
  }
  .container-team::after {
    content: '';

    width: 185px;
    height: 890px;

    position: absolute;
    top: -63px;
    right: 0;

    background: url('../img/bg3.png') no-repeat center center;

    z-index: 0;
  }
  .container-team .row {
    max-width: 1070px;
    margin: auto;
  }

  .title br {
    display: none;
  }
  
  .container-team_person-img {
    width: 190px;
    height: 190px;
  }
  .container-team_person.active .container-team_person-img {
    width: 230px;
    height: 230px;

    position: relative;
  }
  .container-team .carousel-next,
  .container-team .carousel-prev {
    bottom: 130px;
    top: unset;
    position: relative;
  }
  .container-team .carousel-next {
    right: 330px
  }
  .container-team .carousel-prev {
    left: 330px;
  }
  .container-stats {
    padding: 70px 15px;
  }
  .container-stats::before {
    left: 5%;
    top: 90px;
  }
  .container-stats::after {
    right: 190px;
    top: 50px;
  }
  .container-stats .row {
    max-width: 900px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .container-process .row {
    display: flex;
    justify-content: space-between;

    max-width: 1200px;
    margin: auto;
  }
  .process-block:last-child .process-block_number::before {
    content: '';
  }
  .process-block .process-block_number::before {
    bottom: 10px;
  }

  .container-product {
    padding-bottom: 110px;
    position: relative;
  }

  .container-product .row::after {
    content: '';

    width: 450px;
    height: 450px;  

    position: absolute;
    right: 0;
    bottom: -40px;

    background: url('../img/bg4.png') no-repeat center center;
    background-size: contain;

    z-index: 0;
  }
  .container-product .title {
    margin-bottom: 70px;
  }

  .container-product .row {
    max-width: 860px;
    margin: auto;
  }
  .container-product .product-row {
    max-width: 360px;
    margin-bottom: 30px;
    margin-left: unset;
    margin-right: unset;

  }
  
  .container-price {
    margin-bottom: 0;
  }
  .container-price .row {
    max-width: 960px;
    margin: auto;
  }
  .container-price .row.slider-products {
    max-width: 1170px;
    padding: 0 30px;
  }
  .container-review {
    padding-bottom: 200px;
  }
  
  .container-delivery {
    max-width: 1070px;
    margin: 0 auto;
  }
  /* .container-delivery >div {
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    float: right;
  }
  .container-delivery >div::before {
    content: '';

    width: 450px;
    height: 300px;  

    position: absolute;
    left: 0;
    bottom: 50px;

    background: url('../img/bg5.png') no-repeat center center;
    background-size: contain;

    z-index: 0;
  } */
  /* .container-delivery p br {
    display: none;
  } */
  .container-delivery .title {
    margin-bottom: 10px;
  }
  .container-delivery .common-button_orange {
    float: left;
  }
  .container-contacts {
    margin-bottom: 0;
  }
  .container-contacts .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1290px;
    margin: 0 auto;
  }
  .container-contacts .title::after {
    margin: 20px auto;
  }
  .container-contacts_wrap {
    display: flex;
  }
  .container-contacts_wrap div {
    margin: 0 30px;
  }
  .container-contacts::before {
    top: -25px;
    right: 150px;
  }
  

  /* .modal {
    background-color: #fff;
    width: 500px;
    max-height: calc(100% - 60px);
    margin: 30px auto;
    border-radius: 20px;

    padding-top: 80px;

    background-position-x: 0px;
    background-position-y: -50px;
  } */
  .modal-close {
    margin-left: auto;
    margin-right: 20px;
  }
  .modal-close:hover {
    background: linear-gradient(180deg, #FEAE37 0%, #C96126 100%), #C4C4C4;
  }
  .carousel-team .amp-carousel-button-next,
  .carousel-team .amp-carousel-button-prev {
    display: block;
  }
  .slider-products .swiper-slide {
    height: unset;
  }
}  

@media (min-width: 969px) and (max-width: 1025px) {
  .container-about {
    padding-left: 30px;
    padding-right: 30px;
  }
  .container-team, .container-product {
    padding-top: 130px;
    margin-bottom: 0;
  }
  .container-review {
    padding-bottom: 150px;
  }
}

@media (min-width: 1025px) {
  /* .review-card {
    max-width: unset;
    margin: 0 20px;
  } */
  .container-review .carousel-pagination {
    display: none;
  }
  .container-review .carousel-next,
  .container-review .carousel-prev {
    display: flex;
    top: unset;
    bottom: 5px;
    position: absolute;
  }
  .container-review .carousel-next {
    right: calc(49vw - 40px);
  }
  .container-review .carousel-prev {
    left: calc(49vw - 40px);
  }
  .container-contacts::after {
    content: '';

    width: 200px;
    height: 270px;

    position: absolute;
    bottom: 0;
    left: 75px;

    background: url('../img/chamomile-img.png') no-repeat center center;
    background-size: contain;

    z-index: 0;
  }
}

@media (min-width: 1600px) {
  .container-review {
    padding-bottom: 260px;
  }
}