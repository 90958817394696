.header-carousel {
  height: calc(100vh - 85px);
}
.rs-carousel-slider-item {
  object-fit: cover;
}
.rs-navbar.navbar {
  background-color: #000;
}
.rs-navbar-header {
  height: unset;
}

.rs-navbar.navbar 
.rs-navbar-body {
  display: flex;
  align-items: center;
}
.navbar {
  height: 85px;
}
.navbar a {
  display: flex;
  padding: 15px 10px;

  color: #FEAE37;
  font-family: 'Zelek';
  font-size: 20px;
}
.navbar a.logo {
  display: inline-flex;
  align-items: center;

  padding: 5px;
  margin-right: 30px;
  
  font-family: 'Audiowide';
  color: #FEAE37!important;
}
.navbar a.logo img {
  height: 3rem;
  margin-right: 1rem;
}
.navbar a.logo:hover {
  text-decoration: none;
}
.navbar .navbar-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: auto;
  width: 100%;
  max-width: 1170px;
}
.navbar .navbar-desktop>div{
  display: flex;
  align-items: center;
}
.navbar .navbar-mobile {
  display: none;
  justify-content: space-between;
  width: 100%;
}
.navbar .navbar-mobile button {
  background: none;
  border: none;
  padding: 5px;
}

.mobile-menu {
  max-width: 150px;
}
.mobile-menu .rs-drawer-body {
  display: flex;
  flex-direction: column;
}
.mobile-menu .rs-drawer-body a {
  padding: 10px 5px;
  color: #FEAE37;
  font-family: 'Zelek';
  font-size: 20px;
}

/* .worker-carousel .rs-carousel-slider-item {
  min-width: calc(100% / 3)!important;
} */
/* .worker-carousel .rs-carousel-slider {
  width: 100%!important;
  display: flex;
} */

@media (max-width: 968px) {
  .navbar .navbar-desktop {
    display: none;
  }
  .navbar .navbar-mobile {
    display: flex;
  }
  .navbar a.phone {
    font-size: 17px;
    padding: 5px 0px;
  }
  .navbar {
    height: 60px;
  }
  .phone-icon-wrap {
    width: 1.5rem;
    height: 1.5rem;
  }
  .navbar a.logo {
    height: 100%;
    padding: 0;
    margin: 0;
  }
  .navbar a.logo img {
    margin: unset;
    height: 40px;
  }
}

.contact-map_wrap {
  width: 100%;
  margin: 0;
  height: 24rem;
  z-index: 1;
}
.contact-map_wrap>div {
  height: 100%;
}
.contact-map_wrap .gm-style-iw-c {
  padding: 0!important;
  overflow: unset!important;
}
.contact-map_wrap .gm-style-iw-d {
  padding: 0!important;
  overflow: unset!important;
  height: 0px;
}
.contact-map_wrap .gm-ui-hover-effect {
  display: none!important;
}
.contact-map_wrap .gm-style-iw-t::after {
  content: unset;
}
.contact-map_wrap .gm-style-iw-t {
  bottom: 150px!important;
  right: 380px!important;
}

@media (min-width: 968px) {
  .contact-map_wrap {
    height: 30rem;
  }
}

.contact-map_card {
  padding: 1.25em 2em 0.25em 1.75em;

  box-shadow: 0px 0px 12px 6px rgba(255,255,255,0.5);
  background-color: #0c111d;
  border-radius: 12px;
}
.contact-map_card h2, .contact-map_card_mobile h2 {
  margin-bottom: .3em;

  font-size: 2rem;
  font-weight: 800;
  color:#fff;
}




.contact-map_card-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: .5em;
  padding-left: 1.75rem;

  position: relative;

  color: #fff;
  font-size: 1rem;
}
.contact-map_card-row p {
  color: rgb(255 255 255 / 80%);
}

.contact-map_card-row a {
  line-height: 1.5;
  color: #fff;
}
.contact-map_card-row span {
  font-weight: 700;
}
.contact-map_card-row img {
  width: 1.1rem;
  height: 1.1rem;
  margin-top: 3px;
  
  position: absolute;
  left: 0;
}

.contact-socials {
  display: flex;
  padding: 0.25rem 0.5rem 0.75rem;
}
.contact-socials a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.7rem;
  height: 2.7rem;
  margin-right: 0.8em;
  padding: 0.7em;
  border: 1.5px solid #f96514;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s ease 0s;
  box-sizing: border-box;
  cursor: pointer;
}
.contact-socials a svg {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease 0s;
}
.contact-socials a:hover {
  background-color: #f96514;
}
.contact-socials a:hover svg path{
  fill: #fff;
}
.contact-map_card_mobile {
  padding: 1.25em 2em 0.25em 1.75em;
  background-color: #0c111d;

  display: none;
}

@media (max-width: 968px) {
  .contact-map_card {
    display: none;
  }
  .contact-map_card_mobile {
    display: block;
  }
}
@media (max-width: 1024px) {
  .contact-map_wrap .gm-style-iw-c {
    margin-left: 50px;
  }
}


.worker-slider_item {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 30px;

}
.worker-slider_item img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
}
.worker-slider_item p {
  display: none;
  margin: 20px 0 10px;

  text-align: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.56;
  color: #0B0A0A;
}
.worker-slider_item p:last-child {
  font-family: 'Avenir Next Cyr',serif;
  font-size: 16px;
  line-height: 1.52;
  color: #0B0A0A;
  text-align: center;
  font-weight: unset;
  margin: 0;
  display: none;
}


.swiper-slide-active.worker-slider_item p {
  display: block;
}
.swiper-slide-active.worker-slider_item {
  margin-top: unset;
}
.swiper-slide-active.worker-slider_item img {
  width: 230px;
  height: 230px;
}
@media (max-width: 768px) {
  .worker-slider_item img {
    width: 140px;
    height: 140px;
  }
  .swiper-slide-active.worker-slider_item img {
    width: 200px;
    height: 200px;
  }
  p.worker-slider_item-extraText {
    margin-bottom: 3px;

    line-height: 1;
  }
}

.swiper-pagination {
  bottom: 0px!important;
}
.kek .swiper-button-next {
  right: 30%!important;
  top: 30%!important;
  width: 2rem!important;
  height: 2rem!important;

  background: url(./img/rightArrow.png) center center;
  background-size: 2rem;
  background-repeat: no-repeat;
}
.kek .swiper-button-next:after,
.kek .swiper-button-prev:after,
.about-slider .swiper-button-next:after,
.about-slider .swiper-button-prev:after {
  content: unset!important;
}

.about-slider .swiper-button-next{
  width: 2rem!important;
  height: 2rem!important;
  right: calc(50% - 3rem)!important;

  background: url(./img/rightArrow.png) center center;
  background-size: 2rem;
  background-repeat: no-repeat;
}
.about-slider .swiper-button-prev {
  width: 2rem!important;
  height: 2rem!important;
  left: calc(50% - 3rem)!important;

  background: url(./img/leftArrow.png) center center;
  background-size: 2rem;
  background-repeat: no-repeat;
}

.kek .swiper-button-prev {
  left: 30%!important;
  top: 30%!important;

  width: 2rem!important;
  height: 2rem!important;

  background: url(./img/leftArrow.png) center center;
  background-size: 2rem;
  background-repeat: no-repeat;
}
.swiper-container {
  padding-bottom: 70px!important;
}
.about-slider .swiper-container {
  padding-bottom: 50px!important;
}
.swiper-pagination-bullet-active {
  background: #FEAE37!important;
}
.swiper-pagination-bullet {
  background: #FEAE37!important;
}
.about-slider .swiper-slide {
  width: 100%!important;
}
@media (max-width: 767px) {
  .kek .swiper-button-prev {
    left: 20px!important;
  }
  .kek .swiper-button-next {
    right: 20px!important;
  }
  .swiper-container {
    padding-bottom: 60px!important;
  }
  .container-process .row {
    margin: 0;
  }
}


.review-slider .swiper-button-next {
  right: calc(50% - 3.5rem)!important;
  top: unset!important;
  bottom: 0!important;
  width: 3rem!important;
  height: 3rem!important;

  background: url(./img/reviewArrowRight.png) center center;
  background-size: 3rem;
  background-repeat: no-repeat;
}

.review-slider .swiper-button-prev {
  left: calc(50% - 3.5rem)!important;
  top: unset!important;
  bottom: 0!important;

  width: 3rem!important;
  height: 3rem!important;

  background: url(./img/reviewArrowLeft.png) center center;
  background-size: 3rem;
  background-repeat: no-repeat;
}

.review-slider .swiper-button-next:after,
.review-slider .swiper-button-prev:after {
  content: unset!important;
}
.review-slider {
  max-width: 1080px;
  margin: auto;
}


@media (max-width: 968px) {
  .lel {
    display: block;
    margin: unset;
  }
}


.about-slider .swiper-button-prev,
.about-slider .swiper-button-next{
  top: unset!important;
  bottom: 0!important;
  color: #FEAE37;
  margin-top: -40px;
}


.contact-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.contact-block:last-child {
  width: 45%;
}
@media (max-width: 968px) {
  .about-slider {
    margin: unset;
  }
  .contact-block:last-child {
    width: 100%;
  }
}

.contact-block_row {
  display: flex;
  align-items: center;

  margin-bottom: 10px;
}
.contact-block_row a, .contact-block_row p {
  color: #000;
  font-weight: 600;
  font-size: 1.1rem;
  font-family: sans-serif;
  text-align: left;
  line-height: normal;
}
.contact-block_row img {
  margin-right: 10px;
  height: 40px;
  width: 40px;
}

.contact-block_form {
  display: flex;
  flex-direction: column;   
  align-items: flex-start;
}
.contact-block_form p {
  color: #000;
  font-weight: 600;
  font-size: 1.1rem;
  font-family: sans-serif;

  margin-bottom: 5px;
}
.contact-block_form input,.contact-block_form textarea {
  width: 100%;
  padding: 6px 10px;
  margin-bottom: 10px;

  font-size: 1rem;
  font-weight: 500;

  resize: none;

  border: 1px solid #FEAD37;
  border-radius: 6px;
}
@media (max-width: 968px) {
  .contact-block {
    width: 100%;
    margin: 0 auto 30px;
    max-width: 300px;
  }
  .contact-block_form {
    width: 100%;
    align-items: center;
  }
}

@media (min-width: 969px) {
  .slider-products .swiper-pagination{
    display: none;
  }
}
@media (max-width: 969px) {
  .slider-products {
    margin: 0;
  }
  .header {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-content: center;

    background: linear-gradient(179.62deg, rgba(30, 20, 32, 0) 3.14%, #1E1420 90%);
  }
  .header-carousel {
    height: calc(100vh - 60px);
  }
}
@media (min-width: 767px) and (max-width: 968px) {
  .container-team {
    padding-top: 70px;
  }
  .container-process .row {
    flex-direction: column;
  }
  .contact-map_card_mobile {
    display: flex;
    flex-direction: column;

    padding-left: 10%;
  }
  .container-about {
    padding-left: 30px;
    padding-right: 30px;
  }
  .about-slider {
    padding: 0 80px;
  }
  .about-slider .swiper-container {
    padding-bottom: 60px!important;
  }
  .about-slider .swiper-button-prev,
  .about-slider .swiper-button-next,
  .kek .swiper-button-next,
  .kek .swiper-button-prev {
    width: 2.5rem!important;
    height: 2.5rem!important;
    background-size: 2.5rem;
  }
  .header-carousel { 
    height: 70vh;
  }
}

.label-and-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 1rem;
}

.label-and-input p {
  margin-bottom: 0.5rem;

  font-size: 16px;
}

.order-modal .modal-header {
  padding-top: 60px;
  border-bottom: unset;
  font-size: 36px;
  line-height: 1.4;
  color: #FEAE37;
  text-align: center;
  font-weight: bold;
  font-family: Zelek;
  align-items: center;
  justify-content: center;
}
.order-modal .modal-header .close {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  margin: unset;
  height: 40px;
  padding: 0;
  opacity: 1;
  text-shadow: none;

  position: absolute;
  top: 10px;
  right: 10px;
  
  border: none;
  border-radius: 50%;
  background: linear-gradient(180deg, #FEAD37 0%, #FE8337 100%);
}

.order-modal .modal-header .close:hover {
  background: linear-gradient(180deg, #FEAE37 0%, #C96126 100%), #C4C4C4;
}
.order-modal .modal-content {
  background: url('./img/modal-bg.png') no-repeat center -50px #fff;
  background-size: 85%;
  background-clip: padding-box;
}
.order-modal form {
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .order-modal .modal-content {
    background: url('./img/modal-bg.png') no-repeat center -30px #fff;
  }
}

.modal-input-block.rs-input-group {
  display: flex;

  border-radius: 50px;
  border: none;

  overflow: hidden;
}

.modal-input-block a {
  padding: 8px 16px;

  font-size: 30px;
  color: #000;
  font-weight: bold;
  font-family: Zelek;
  background-color: #FEAE37;
}
.modal-input-block a:hover {
  color: #FEAE37;
}

.modal-input-block input {
  margin-bottom: 0;
  padding-left: 20px;
  text-align: center;
}
.modal-input-block .rs-input-number-btn-group-vertical {
  display: none;
}

.sold-out-img {
  width: 200px;
  max-width: 50%;

  position: absolute;
  left: -3px;
}
